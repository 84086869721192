.hero {
  background-image: url('/public/IMG_2154.png'); 
  background-size: cover;          
  background-position: center;   
  padding: 100px 20px;
  color: white;                    
  text-align: center;
  min-height: 200px;              
}

.hero .hero-buttons {
  margin-top: 20px;
}

.hero .btn-primary, .hero .btn-secondary {
  margin: 10px;
}
@media screen and (max-width: 768px) {
  .hero {
    padding: 50px 10px; 
    background-size: cover; 
    min-height: 300px; 
  }

  .hero h1 {
    font-size: 1.5em;
  }

  .hero p {
    font-size: 1em; 
  }

  .hero-buttons {
    flex-direction: column; 
    margin-top: 10px; 
  }

  .hero .btn-primary, .hero .btn-secondary {
    margin: 5px 1; 
    width: 100%; 
  }
}