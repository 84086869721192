.about {
  padding: 50px 20px;
  text-align: left;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-image {
  flex-shrink: 0; 
  margin-right: 90px; 
}

.about-image img {
  width: 300px; 
  height: 350px;
  border-radius: 10px;
}

.about-text {
  flex: 1;
}

.about-text h2 {
  font-size: 2em;
  color: #05073D;
}

.about-text p {
  font-size: 1em;
  color: #333; }
  @media screen and (max-width: 768px) {
    .about-container {
      flex-direction: column; 
      text-align: center; 
    }
  
    .about-image {
      margin: 0 auto 20px auto;
    }
  
    .about-image img {
      width: 200px; 
      height: 200px; 
    }
  
    .about-text h2 {
      font-size: 1.5em; 
    }
  
    .about-text p {
      font-size: 1em; 
    }
  }