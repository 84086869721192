#reviews h2 {
  color: #EB662B; /* Couleur bleu marin pour le titre des avis */
}
#contact h2 {
  color:#05073D ; }
#features h2 {
  color: #EB662B;
}
#about h2 {
  color: #EB662B;
}