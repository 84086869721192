.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #EB662B; 
  color: #FFFFFF; 
}

.navbar-brand {
  font-size: 1.5em;
}

.navbar-menu {
  position: relative;
}

.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #EB662B; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
}

.menu-icon {
  width: 30px;
  height: 3px;
  background-color: #FFFFFF; 
  margin: 3px 0;
}

.menu-toggle:hover .menu-icon {
  background-color: #D9534F; 
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #FFFFFF; 
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 200px; 
}

.dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  color: #05073D; 
  font-size: 1em;
}

.dropdown-item:hover {
  background-color: #EB662B; 
  color: #FFFFFF; 
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #EB662B;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .nav-links {
    display: none; 
  }

  .hamburger-menu {
    display: block; 
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: #05073D;
    position: absolute;
    top: 60px;
    right: 20px;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu a {
    color: white;
    margin: 10px 0;
    text-align: center; 
  }
}