.contact {
  background-color: #f4f4f4;
  padding: 50px 20px;

}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.contact form input,
.contact form textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
 
}

.contact form button {
  margin-top: 20px;
  
}
.contact {
  background-color: #f4f4f4;
  padding: 50px 20px;

}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.contact form input,
.contact form textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
 
}

.contact form button {
  margin-top: 20px;
  
}
.btn-secondary {
  background-color: #EB662B; 
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  text-align: center;
}

.btn-secondary{
  background-color: #d9534f; 
}
@media screen and (max-width: 768px) {
  .contact {
    padding: 20px 10px; 
  }

  .contact form {
    width: 100%; 
  }

  .contact form input,
  .contact form textarea {
    width: 80%;
    padding: 10px; 
  }

  .contact form button {
    width: 80%; 
    padding: 10px; 
  }
}