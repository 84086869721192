.reviews {
  padding: 50px 20px;
  text-align: left;
}

.reviews h2 {
  margin-bottom: 20px;
}

.reviews-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.review {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px);
  box-sizing: border-box;
  text-align: left;
}

.review h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.review p {
  margin: 0 0 10px 0;
  font-size: 1em;
}

.stars {
  display: flex;
}

.star {
  color: #EB662B;
  margin-right: 5px;
}

.star.empty {
  color: #ddd;
}
@media screen and (max-width: 768px) {
  .reviews-list {
    flex-direction: column; 
  }

  .review {
    width: 100%; 
    margin: 10px 0; 
  }

  .review h3 {
    font-size: 1.2em; 
  }

  .review p {
    font-size: 1em; 
  }

  .stars {
    justify-content: center; 
  }
}