.features {
  padding: 50px 20px;
  text-align: left;
}

.features-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.features-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.feature-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.feature-icon-container {
  background-color: #fde8e0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-text {
  margin-left: 10px;
  font-size: 1.1em;
}

.features-image {
  flex: 1;
  margin-left: 20px;
}

.moving-image {
  width: 500px;
  height: 440px;
  border-radius: 10px;
  animation: float 7s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}     
@media screen and (max-width: 768px) {
  .features-content {
    flex-direction: column;
    text-align: center; 
  }

  .features-image {
    margin: 20px 0; 
    width: 100%; 
    height: auto; 
  }

  .feature-item {
    margin: 20px 0;
  }

  .moving-image {
    width: 100%; 
    height: auto; 
  }
}